import React, {lazy, Suspense} from "react";

// core components
import Menu from "components/Menu";
import Masterplan from 'components/Masterplan'
import Video from 'components/Video'
import Ubicacion from 'components/Ubicacion'
import Contacto from 'components/Contacto'
import Footer from "components/Footer"
import HeaderApp from 'components/HeaderApp'
const Proyecto = lazy(() =>import('components/Proyecto'))
const Galeria = lazy(() => import('components/Galeria'))



const renderLoader = () => <p>Loading</p>

function Index() {
  document.documentElement.classList.remove("nav-open");

  
  React.useEffect(() => {
    document.body.classList.add("section-page");
    
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
    return function cleanup() {
      document.body.classList.remove("section-page");
    };
  });

  
  return (
    <>
      <Menu />
      <HeaderApp />
      <Suspense fallback={renderLoader()}>
        <Proyecto />
      </Suspense>
      
      <Masterplan />
      <Video />
      <Suspense fallback={renderLoader()}>
        <Galeria />
      </Suspense>
      <Ubicacion/>
      <Contacto />
      <Footer />
    </>
  );
}

export default Index;
