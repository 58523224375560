/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import INSTAGRAM from 'assets/images/instagram.png'
// core components

function Footer() {
  return (
    <>
      <footer className="footer footer-black py-5">
        <Container>
          <Row className="ft justify-content-center">
            <Col className="col-12 col-md-6">
                <span className="ml-5 texto-small">
                  SÍGUENOS EN INSTAGRAM 
                  <a href="https://instagram.com/miradordelcentinela" target="_blank"><img style={{width:'40px'}} src={INSTAGRAM} alt="icon-instagram"/></a>
                </span>   
            </Col>
            <Col className="col-12 col-md-6 pt-3">
              <div className="ml-5 text-left text-md-right">
                <a href="https://instagram.com/miradordelcentinela" target="_blank"><p className="texto-small">@MIRADORDELCENTINELA</p></a>
                <a href="mailto:info@miradordelcentinela.com" target="_blank"><p className="texto-small">info@miradordelcentinela.com</p></a>
                <a href="tel:+56934356391"><p className="texto-small">+56 9 34356391</p></a>
                
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;