import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Map from './Map'

const Ubicacion = () => {
    return(
        <>
      <div className="section section-feature cd-section pb-2" id="ubicacion">
        {/* ********* FEATURES 4 ********* */}
        <div className="features-4 pb-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Ubicación</h2>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="10" className="px-0 px-md-2">
                <Map />
              </Col>
              <Col className="offset-1" md="4">
                {/* <div className="info info-horizontal pt-0 pt-sm-4">
                  <div className="icon icon-info">
                    <i aria-hidden={true} className="nc-icon icon-map-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Indicación 1</h4>
                    <ul className="pl-0">
                      <li> Por ruta (12kms de asfalto) , desviar en dirección al norte hacia  (8 kms de ripio) </li>
                    </ul>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-success">
                    <i aria-hidden={true} className="nc-icon icon-map-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Desde ...</h4>
                    <ul className="pl-0">
                      <li>Por ruta (8 kms de ripio) </li>
                    </ul> 
                  </div>
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 4 ********* */}

      </div>
    </>
    )
}
export default Ubicacion